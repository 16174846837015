import {FeatureCodes} from '@/branchmanager/app/enums/featureCodesEnum'

export interface LegacyModule {
  name: string,
  path: string,
  featureCode: string
}
export const legacyManagementModules: LegacyModule[] = [
  {
    name: "Rules",
    path: "/rules",
    featureCode: FeatureCodes.RULES
  }
];