import "reflect-metadata"; // Always on top
import { createApp } from "vue";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import App from "./App.vue";
import router from "./router";
import container from "@/common/dependency.configs";
import { useE2eStore } from "./stores/e2eStore";
import DEPENDENCYTYPES from "@/common/dependency.types";
import type IWorkspaceStore from "@/common/services/Workspace/IWorkspaceStore";
import { WorkspaceStore } from "./stores/WorkspaceStore";
import { WorkspaceMetadataStore } from "./stores/WorkspaceMetadataStore";
import type IWorkspaceMetadataStore from "@/common/services/Workspace/IWorkspaceMetadataStore";
import SsoRedirectFactory from "./states/sso-redirect/ssoRedirect.factory";
import EnrollmentCipFactory from "./app/factories/enrollment/EnrollmentCipFactory";
import OpenAuthFactory from "@/open/app/factories/authorization/OpenAuthFactory";

import bltForm from "@/open/components/bltForm1.vue";
import bltWizard from "@/open/components/bltWizard.vue";
import openToolbar from "@/open/components/open-toolbar/openToolbar.vue";
import footerComponent from "@/open/components/footer-component/footer-component.vue";
import { useAllDirectives } from "@/common/directives/appch.directives.use";
import { useAllComponents } from "@/common/components/appch.components.use";
import APP_CODES from "@/common/enums/appCodeEnum";
import type IRouterService from "@/common/services/utils/IRouterService";
import RouterService from "./router/RouterService";
import GlobalError from "./handlers/GlobalError";
import { BltProgressBar, BannoEnrollmentForm } from "./components/open.components.module";
import { QueryPropertiesService } from "@/open/app/factories/config/queryProperties.service";
import { PlaidStore } from "@/open/stores/PlaidStore";
import type { IPlaidStore } from "@/common/services/Vendor/Plaid/IPlaidStore";
import RuleDecisionFactory from "@/open/states/workflow/enrollment/info/ruleDecision/RuleDecisionFactory";
import ColorSchemeUtil from "@/open/services/utils/ColorSchemeUtil";
import bltScreen from "@/open/components/bltScreen.vue";
import { $injStr } from "@/common/decorators/depinject";
import { VueMaskDirective } from "v-mask";
import CardContentHeightUtil from "@/open/services/utils/CardContentHeightUtil";
import type IHttpErrorHandler from "@/common/services/connect/IHttpErrorHandler";
import OpenHttpErrorHandler from "@/open/services/OpenHttpErrorHandler";
import {DisclosureStore} from "@/open/stores/DisclosureStore";
import {CipStore, type ICipStore} from "@/open/stores/CipStore";
import type {IDisclosureStore} from "@/common/services/Disclosure/IDisclosureStore";
import WorkspacePromoCodeService from "@/common/services/Workspace/WorkspacePromoCodeService";
import type HttpHandlerConfig from "@/common/interfaces/httpHandler/HttpHandlerConfig";
import ApplicantChannelHttpHandlerConfig from "@/open/config/ApplicantChannelHttpHandlerConfig";
import WorkspaceReferralService from "@/common/services/Workspace/WorkspaceReferralService";
import QueryParameterCollectionService
  from "@/common/services/QueryParameterCollectionService/QueryParameterCollectionService";
import DisclosureUtils from "@/common/services/Disclosure/DisclosureUtils";

const app = createApp(App);

app.config.globalProperties.window = <any>window;
(<any>window).APP_CODE = APP_CODES.OPEN;
app.config.globalProperties.window.$inj = $injStr;
app.config.globalProperties.$institutionConfig = window.institutionConfig;

/* To stay on the same Url when browser back button click */
window.onpopstate = function () {
  history.go(0);
};

app.config.errorHandler = GlobalError.handler;
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
app.use(pinia);
app.directive("mask", VueMaskDirective);

useAllDirectives(app);
useAllComponents(app);
app.component("blt-form", bltForm);
app.component("blt-wizard", bltWizard);
app.component("open-toolbar", openToolbar);
app.component("footerComponent", footerComponent);
app.component("blt-progress-bar", BltProgressBar);
app.component("banno-enrollment-form", BannoEnrollmentForm);
app.component("blt-screen", bltScreen);

container.bind<IHttpErrorHandler>(DEPENDENCYTYPES.IHttpErrorHandler).to(OpenHttpErrorHandler).inSingletonScope();
container.bind<IWorkspaceStore>(DEPENDENCYTYPES.IWorkspaceStore).toConstantValue(WorkspaceStore());
container
  .bind<IWorkspaceMetadataStore>(DEPENDENCYTYPES.IWorkspaceMetadataStore)
  .toConstantValue(WorkspaceMetadataStore());
container.bind<IPlaidStore>(DEPENDENCYTYPES.IPlaidStore).toConstantValue(PlaidStore());
container.bind<IRouterService>(DEPENDENCYTYPES.IRouterService).to(RouterService).inSingletonScope();
container.bind<HttpHandlerConfig>(DEPENDENCYTYPES.HttpHandlerConfig).to(ApplicantChannelHttpHandlerConfig).inSingletonScope();
container.bind<QueryParameterCollectionService>(QueryParameterCollectionService).toSelf();
container.bind<DisclosureUtils>(DisclosureUtils).toSelf()
container.bind<WorkspacePromoCodeService>(WorkspacePromoCodeService).to(WorkspacePromoCodeService).inSingletonScope();
container.bind<WorkspaceReferralService>(WorkspaceReferralService).to(WorkspaceReferralService).inSingletonScope();
container.bind<SsoRedirectFactory>(SsoRedirectFactory).to(SsoRedirectFactory).inSingletonScope();
container.bind<EnrollmentCipFactory>(EnrollmentCipFactory).to(EnrollmentCipFactory).inSingletonScope();
container.bind<OpenAuthFactory>(OpenAuthFactory).to(OpenAuthFactory).inSingletonScope();
container.bind<QueryPropertiesService>(QueryPropertiesService).to(QueryPropertiesService).inSingletonScope();
container.bind<RuleDecisionFactory>(RuleDecisionFactory).to(RuleDecisionFactory).inSingletonScope();
container.bind<ColorSchemeUtil>(ColorSchemeUtil).to(ColorSchemeUtil).inSingletonScope();
container.bind<CardContentHeightUtil>(CardContentHeightUtil).to(CardContentHeightUtil).inSingletonScope();
container.bind<IDisclosureStore>(DEPENDENCYTYPES.IDisclosureStore).toConstantValue(DisclosureStore())
container.bind<ICipStore>(DEPENDENCYTYPES.ICipStore).toConstantValue(CipStore())
container.bind<RouterService>(RouterService).to(RouterService).inSingletonScope();

app.use(router);

app.mount("#app");

if ((window as any).Cypress) {
  (window as any).e2e = useE2eStore();
  (window as any).workspaceMetadataStore = WorkspaceMetadataStore();
  (window as any).workspaceStore = WorkspaceStore();
}
