export class BranchLanguageKeysConstant {
  static readonly OPEN_SSO_CONTINUE_WORKFLOW_HEADER: string = "open_sso_continue_workflow_header";
  static readonly OPEN_SSO_CONTINUE_WORKFLOW_BODY: string = "open_sso_continue_workflow_body";
  static readonly MESSAGE_CODE_OPEN_EOD_ERROR_SCREEN: string = "open_eod_error_screen";
  static readonly OPEN_ERROR_HEADER: string = "open_error_header";
  static readonly WEBPAGE_TITLE: string = "webpage_title";
  static readonly GLOBAL_FULL_NAME_PLACEHOLDER: string = "global_full_name_placeholder";
  static readonly OPEN_TOOLBAR_TITLE: string = "open_toolbar_title";
  static readonly APPLICANT_CHANNEL_LOGO: string = "APPLICANT_CHANNEL_LOGO";
  static readonly ALL_BLT_COLORS: string = "bltColors_val";

  // SAVE state dialogs
  static readonly NCR_DIALOG_TITLE: string = "ncr_modal_title";
  static readonly NCR_BUTTON_ACCEPT: string = "ncr_button_accept";
  static readonly ERROR_MESSAGE_REQUIRED: string = "error_message_required";
  static readonly ERROR_MESSAGE_MISMATCH: string = "error_message_mismatch";
  static readonly ERROR_MESSAGE_VALID_DATA: string = "Enter valid data";
  static readonly NCR_ERROR_PASS_UNDER_6: string = "ncr_error_pass_under_6";
  static readonly NCR_ERROR_USER_UNDER_6: string = "ncr_error_user_under_6";
  static readonly NCR_ERROR_PASS_OVER_32: string = "ncr_error_pass_over_32";
  static readonly NCR_ERROR_USER_OVER_256: string = "ncr_error_user_over_256";
  static readonly ERROR_MESSAGE_NCR_VALIDATION: string = "error_message_ncr_validation";

  // CONTINUE state
  static readonly OPEN_CONTINUE_WELCOME: string = "open_continue_welcome";
  static readonly OPEN_CONTINUE_TITLE: string = "open_continue_title";
  static readonly OPEN_CONTINUE_INSTRUCTIONS: string = "open_continue_instructions";
  static readonly OPEN_CONTINUE_LABEL_EMAIL: string = "open_continue_label_email";
  static readonly OPEN_CONTINUE_LABEL_DOB: string = "open_continue_label_dob";
  static readonly OPEN_CONTINUE_LABEL_TAX_ID: string = "open_continue_label_tax_id";
  static readonly OPEN_CONTINUE_LABEL_LAST_NAME: string = "open_continue_label_last_name";

  // INITIAL DISCLOSURE state
  static readonly DISCLOSURE_ACCEPT_TEXT: string = "disclosure_accept_text";
  static readonly DISCLOSURE_NOT_EMAILABLE_MESSAGE: string = "open_disclosure_emailed_failure";

  // PLAID/FINICITY
  static readonly OPEN_FAILED_PLAID_VERIFICATION: string = "open_failed_plaid_verification";
  static readonly PENDING_MANUAL_VERIFICATION: string = "pending_manual_verification";
  static readonly PENDING_AUTOMATIC_VERIFICATION: string = "pending_automatic_verification";
  static readonly OPEN_VALIDATE_PLAID_VALIDATE_BUTTON: string = "open_validate_plaid_validate_button";
  static readonly OPEN_VALIDATE_PLAID_VALIDATE_RETURN_TEXT: string = "open_validate_plaid_validate_return_text";

  // PRODUCT SELECTION state
  static readonly OPEN_PRODUCT_SELECTION_MODAL_TITLE: string = "open_product_selection_modal_title";
  static readonly OPEN_PRODUCT_SELECTION_NO_ACCOUNT_HEADER: string = "open_product_selection_no_account_header";
  static readonly OPEN_PRODUCT_SELECTION_NO_ACCOUNT_BODY: string = "open_product_selection_no_account_body";
  static readonly OPEN_PRODUCT_SELECTION_ADD_BUTTON: string = "open_product_selection_add_button";
  static readonly OPEN_PRODUCT_INPUT_INTENTION: string = "branch_product_input_intention";
  static readonly OPEN_PRODUCT_INPUT_ACCOUNT_DESCRIPTION: string = "branch_product_input_account_description";
  static readonly BRANCH_PRODUCT_SELECT_TITLE: string = "branch_product_select_title";
  static readonly BRANCH_PRODUCT_ACCOUNT_TITLE: string = "branch_product_accounts_title";

  //identification state
  static readonly BRANCH_IDENTIFICATION_ERROR_REQUIRED: string = "open_identification_error_required";
  static readonly BRANCH_IDENTIFICATION_ERROR_PATTERN: string = "branch_identification_error_pattern";
  static readonly BRANCH_IDENTIFICATION_LABEL_TYPE: string = "branch_id_input_id_type";
  static readonly OPEN_IDENTIFICATION_LABEL_NUMBER: string = "open_identification_label_number";
  static readonly BRANCH_IDENTIFICATION_LABEL_ISSUEDATE: string = "branch_identification_label_issueDate";
  static readonly BRANCH_IDENTIFICATION_LABEL_EXPIRYDATE: string = "branch_identification_label_expiryDate";
  static readonly BRANCH_IDENTIFICATION_LABEL_COUNRTY: string = "branch_id_input_issue_country";
  static readonly BRANCH_IDENTIFICATION_LABEL_STATE: string = "branch_id_input_issue_state";
  static readonly BRANCH_IDENTIFICATION_ADD_BUTTON: string = "branch_id_button_add";
  static readonly BRANCH_ID_BUTTON_VIEW: string = "branch_id_button_view";
  //phone state
  static readonly OPEN_PHONE_LABEL_TYPE: string = "branch_phone_input_phone_type";
  static readonly OPEN_PHONE_LABEL_PHONE_NUMBER: string = "branch_phone_input_phone_number";
  static readonly OPEN_PHONE_ADD_BUTTON: string = "branch_phone_button_add";

  //Address state
  static readonly OPEN_ADDRESS_LABEL_TYPE_CODE: string = "branch_address_input_address_type";
  static readonly OPEN_ADDRESS_MIRROR_PRIMARY: string = "open_address_mirror_primary";
  static readonly OPEN_ADDRESS_LABEL_ADDRESS1: string = "branch_address_input_line_one";
  static readonly OPEN_ADDRESS_LABEL_ADDRESS2: string = "branch_address_input_line_two";
  static readonly OPEN_ADDRESS_LABEL_COUNTRY: string = "branch_address_input_country";
  static readonly OPEN_ADDRESS_LABEL_ZIP_CODE: string = "branch_address_input_zip";
  static readonly OPEN_ADDRESS_LABEL_CITY: string = "branch_address_input_city";
  static readonly OPEN_ADDRESS_LABEL_STATE: string = "branch_address_input_state";
  static readonly OPEN_ADDRESS_ADD_BUTTON: string = "branch_address_button_add";

  // Person Info state
  static readonly OPEN_PERSONINFO_LABEL_GENDER: string = "branch_personinfo_label_gender";
  static readonly OPEN_PERSONINFO_LABEL_SALUTATION: string = "branch_person_input_salutation";
  static readonly OPEN_PERSONINFO_SIGNATURE_HEADER: string = "branch_personinfo_signature_header";
  static readonly BRANCH_PERSONINFO_PERSON_TYPE: string = "branch_person_input_person_type";
  static readonly BRANHCH_PERSON_BUTTON_SCAN: string = "branch_person_button_scan";
  static readonly OPEN_PERSONINFO_FIRSTNAME: string = "branch_person_input_first_name";
  static readonly OPEN_PERSONINFO_MIDDLENAME: string = "branch_person_input_middle_name";
  static readonly OPEN_PERSONINFO_LASTNAME: string = "branch_person_input_last_name";
  static readonly OPEN_PERSONINFO_SUFFIX: string = "branch_person_input_suffix";
  static readonly OPEN_PERSONINFO_TAXID: string = "branch_person_input_tax_id";
  static readonly OPEN_PERSONINFO_DOB: string = "branch_person_input_dob";
  static readonly BRANCH_PERSON_TITLE: string = "branch_person_title";

  // Wolters Kluwer state
  static readonly NO_QUESTIONS: string = "branch_wk_NoQuestions";

  // Right Of Survivorship state
  static readonly RIGHT_OF_SURVIVORSHIP_TITLE: string = "branch_right_of_survivorship_title";
  static readonly RIGHT_OF_SURVIVORSHIP_TRUE: string = "branch_right_of_survivorship_true";
  static readonly RIGHT_OF_SURVIVORSHIP_FALSE: string = "branch_right_of_survivorship_false";

  // Existing Applicant state
  static readonly EXISTING_LABEL_LAST_NAME: string = "open_existing_label_last_name";
  static readonly EXISTING_LABEL_DOB: string = "open_existing_label_dob";
  static readonly EXISTING_LABEL_LAST_4_SSN: string = "open_existing_label_last_4_tax_id";
  static readonly EXISTING_LABEL_ACCOUNT_TYPE: string = "open_existing_label_account_type";
  static readonly EXISTING_LABEL_ACCOUNT_NUMBER: string = "open_existing_label_account_number";

  // Questionaaire state
  static readonly NUMBER_VALIDATION_MESSAGE: string = "Must be an integer.";

  // Wall state
  static readonly WALL_MESSAGE_ORIGINATOR: string = "wall_message_originator";
  static readonly WALL_MESSAGE_INVITED: string = "wall_message_invited";

  // Employment state
  static readonly BRANCH_EMPLOYMENT_LABEL_EMPLOYER: string = "branch_employment_label_employer";
  static readonly BRANCH_EMPLOYMENT_LABEL_OCCUPATION: string = "branch_employment_label_occupation";
  static readonly BRANCH_EMPLOYMENT_LABEL_TITLE: string = "branch_employment_label_title";
  static readonly BRANCH_EMPLOYMENT_LABEL_ADDRESS1: string = "branch_employment_label_address1";
  static readonly BRANCH_EMPLOYMENT_LABEL_ADDRESS2: string = "branch_employment_label_address2";
  static readonly BRANCH_EMPLOYMENT_LABEL_COUNTRY: string = "branch_employment_label_country";
  static readonly BRANCH_EMPLOYMENT_LABEL_ZIP_CODE: string = "branch_employment_label_zip_code";
  static readonly BRANCH_EMPLOYMENT_LABEL_STATE: string = "branch_employment_label_state";
  static readonly BRANCH_EMPLOYMENT_LABEL_CITY: string = "branch_employment_label_city";
  static readonly BRANCH_EMPLOYMENT_LABEL_PHONE_NUMBER: string = "branch_employment_label_phone_number";
  static readonly BRANCH_EMPLOYMENT_LABEL_YEARS: string = "branch_employment_label_years";
  static readonly BRANCH_EMPLOYMENT_LABEL_MONTHS: string = "branch_employment_label_months";
  static readonly BRANCH_EMPLOYMENT_MATCH_ERROR: string = "Value does not match one of the options";

  // BusinessInfo
  static readonly OPEN_BUSINESS_INFO_LABEL_BUSINESSNAME: string = "branch_business_info_label_businessName";
  static readonly OPEN_BUSINESS_INFO_LABEL_DBA: string = "branch_business_info_label_dba";
  static readonly OPEN_BUSINESSINFO_LABEL_BUSINESS_TYPE: string = "branch_business_info_label_businessType";
  static readonly OPEN_BUSINESS_INFO_LABEL_TAX_ID_TYPE: string = "branch_business_info_label_taxIdType";
  static readonly OPEN_BUSINESS_INFO_OPTION_EIN: string = "branch_business_info_option_EIN";
  static readonly OPEN_BUSINESS_INFO_OPTION_SSN: string = "branch_business_info_option_SSN";
  static readonly OPEN_BUSINESS_INFO_LABEL_STATE: string = "branch_business_info_label_state";
  static readonly OPEN_BUSINESS_INFO_LABEL_COUNTRY: string = "branch_business_info_label_country";
  static readonly OPEN_BUSINESS_INFO_FOR_PROFIT: string = "branch_business_info_forprofit_label";
  static readonly OPEN_BUSINESS_INFO_FOR_PROFIT_TRUE: string = "branch_business_info_forprofit_true_label";
  static readonly OPEN_BUSINESS_INFO_FOR_PROFIT_FALSE: string = "branch_business_info_forprofit_false_label";
  static readonly OPEN_BUSINESS_INFO_EIN_ERROR: string = "branch_business_info_ein_error";
  static readonly OPEN_BUSINESS_INFO_TAXID_EXISTING_IN_CORE: string =
    "branch-validation-error--taxIdExistingInCoreBusiness";
  static readonly OPEN_BUSINESS_INFO_LABEL_NAICS: string = "branch_business_info_label_NAICS";
  static readonly OPEN_BUSINESS_INFO_LABEL_DOE: string = "branch_business_info_label_doe";
  static readonly OPEN_BUSINESS_INFO_LABEL_TAX_ID = "branch_business_info_label_taxId";

  //Demographics
  static readonly BRANCH_DEMOGRAPHICS_EDUCATIONLEVEL: string = "branch_demographics_educationLevel";
  static readonly BRANCH_DEMOGRAPHICS_INCOMELEVEL: string = "branch_demographics_incomeLevel";
  static readonly BRANCH_DEMOGRAPHICS_MARITALSTATUS: string = "branch_demographics_maritalStatus";
  static readonly BRANCH_DEMOGRAPHICS_LANGUAGE: string = "branch_demographics_language";
  static readonly BRANCH_DEMOGRAPHICS_EMPLOYMENTSTATUS: string = "branch_demographics_employmentStatus";
  static readonly BRANCH_DEMOGRAPHICS_CITIZENSHIP: string = "branch_demographics_citizenship";
  static readonly BRANCH_DEMOGRAPHICS_ETHNICITY: string = "branch_demographics_ethnicity";
  static readonly BRANCH_DEMOGRAPHICS_RACE: string = "branch_demographics_race";

  // Capture state
  static readonly OPEN_CAPTURE_OCR_POST_FAILED: string = "open_capture_ocr_post_failed";
  static readonly OPEN_CAPTURE_INSTRUCTIONS_FOCUS: string = "open_capture_instructions_focus";
  static readonly OPEN_CAPTURE_INSTRUCTIONS_HORIZONTAL: string = "open_capture_instructions_horizontal";
  static readonly OPEN_CAPTURE_INSTRUCTIONS_BORDER: string = "open_capture_instructions_border";
  static readonly OPEN_CAPTURE_INSTRUCTIONS_SKEW: string = "open_capture_instructions_skew";
  static readonly OPEN_CAPTURE_SCAN_BUTTON: string = "open_capture_scan_button";

  // Business Continue state
  static readonly OPEN_BUSINESS_CONTINUE_WELCOME: string = "open_business_continue_welcome";
  static readonly OPEN_BUSINESS_CONTINUE_INSTRUCTIONS: string = "open_business_continue_instructions";
  static readonly OPEN_BUSINESS_CONTINUE_BUSINESS_NAME_LABEL: string = "open_business_continue_business_name_label";
  static readonly OPEN_BUSINESS_CONTINUE_TAX_ID_LABEL: string = "open_business_continue_tax_id_label";
  static readonly OPEN_BUSINESS_CONTINUE_TAX_ID_HINT: string = "open_business_continue_tax_id_hint";
  static readonly OPEN_BUSINESS_CONTINUE_DOE_LABEL: string = "open_business_continue_date_of_establishment";

  // Business ownership state
  static readonly OWNERSHIP_LABEL_TITLE: string = "branch_business_ownership_label_title";
  static readonly OWNERSHIP_INCOMPLETE: string = "branch_business_ownership_incomplete";
  static readonly OWNERSHIP_INSTRUCTION: string = "branch_business_ownership_instruction";
  static readonly OWNERSHIP_BUSINESS_CONTROLLER_LABEL: string = "branch_business_ownership_business_controller_label";
  static readonly OWNERSHIP_BUSINESS_CONTROLLER_UNDER: string = "branch_business_ownership_business_controller_under";
  static readonly OWNERSHIP_AUTHORIZED_SIGNER_LABEL: string = "branch_business_ownership_authorized_signer_label";
  static readonly OWNERSHIP_CERTIFYING_PERSON_LABEL: string = "branch_business_ownership_certifying_person_label";
  static readonly OWNERSHIP_CERTIFYING_PERSON_UNDER: string = "branch_business_ownership_certifying_person_under";
  static readonly OWNERSHIP_BENEFICIAL_OWNER_LABEL: string = "branch_business_ownership_beneficial_owner_label";
  static readonly OWNERSHIP_PERCENTAGE_LABEL: string = "branch_business_ownership_business_ownership_percentage_label";
  static readonly OWNERSHIP_OVER_100: string = "branch_business_ownership_over100_warning";
  static readonly OWNERSHIP_CERTIFYING_PERSON_CERTIFICATION_AFTER: string =
    "branch_business_ownership_certifying_person_certification_after";
  static readonly OWNERSHIP_CERTIFYING_PERSON_CERTIFICATION_BEFORE: string =
    "branch_business_ownership_certifying_person_certification_before";
  static readonly OWNERSHIP_CERTIFYING_PERSON_CERTIFICATION_HEADER: string =
    "branch_business_ownership_certifying_person_certification_header";

  // Invite Applicants state
  static readonly OPEN_INVITEAPPLICANTS_FIRST_NAME: string = "open_inviteapplicants_label_firstName";
  static readonly OPEN_INVITEAPPLICANTS_LAST_NAME: string = "open_inviteapplicants_label_lastName";
  static readonly OPEN_INVITEAPPLICANTS_EMAIL: string = "open_inviteapplicants_label_email";
  static readonly OPEN_INVITEAPPLICANTS_BUTTON_ADD: string = "open_inviteapplicants_button_add";
  static readonly OPEN_INVITEAPPLICANTS_INVALID_EMAIL: string = "open_welcome_error_invalid_email";
  static readonly INVITEAPPLICANT_STATE_CIP: string = "inviteApplicant_state_CIP";

  // Email state
  static readonly OPEN_EMAIL_ADD_BUTTON: string = "branch_email_button_add";
  static readonly OPEN_EMAIL_LABEL_TYPE: string = "branch_email_input_email_type";
  static readonly OPEN_EMAIL_LABEL_ADDRESS: string = "branch_email_input_email_address";

  //Prequalification
  static readonly OPEN_PREQUALIFICATION_REJECT_MESSAGE: string = "open_prequalification_reject_message";

  //Emp Channel Applicant State
  static readonly WORKSPACE_CLOSED_BANNER: string = "branch_workspace_closed_banner";

  // Emp WorkspaceDetailState (Landing pages)
  static readonly BRANCH_WORKSPACE_ENROLLMENTS_TITLE: string = "branch_workspace_enrollments_title";
  static readonly BRANCH_WORKSPACE_CLOSED_BANNER: string = "branch_workspace_closed_banner";
  static readonly BRANCH_WORKSPACE_EMPTY_TITLE: string = "branch_workspace_empty_title";
  static readonly BRANCH_WORKSPACE_EMPTY_CLOSED_TITLE: string = "branch_workspace_empty_closed_title";

  // Initial Disclosures
  static readonly BRANCH_INITIAL_DISCLOSURE_BUTTON_EDIT: string = "branch_initial_disclosure_button_edit";
  static readonly BRANCH_INITIAL_DISCLOSURE_BUTTON_RESET: string = "branch_initial_disclosure_button_reset";
  static readonly BRANCH_INITIAL_DISCLOSURE_BUTTON_CLOSE: string = "branch_initial_disclosure_button_close";
  static readonly BRANCH_INITIAL_DISCLOSURE_BUTTON_DECLINE: string = "branch_initial_disclosure_button_decline";
  static readonly BRANCH_INITIAL_DISCLOSURE_BUTTON_PRINT: string = "branch_initial_disclosure_button_print";
  static readonly BRANCH_INITIAL_DISCLOSURE_BUTTON_EMAIL: string = "branch_initial_disclosure_button_email";
  static readonly BRANCH_INITIAL_DISCLOSURE_BUTTON_SUBMIT: string = "branch_initial_disclosure_button_submit";

  // Final Disclosures
  static readonly BRANCH_FINAL_DISCLOSURE_BUTTON_SIGNATURE: string = "branch_final_disclosure_button_signature";
  static readonly BRANCH_FINAL_DISCLOSURE_BUTTON_PRINT: string = "branch_final_disclosure_button_print";
  static readonly BRANCH_FINAL_DISCLOSURE_BUTTON_EMAIL: string = "branch_final_disclosure_button_email";
  static readonly BRANCH_FINAL_DISCLOSURE_BUTTON_CANCEL: string = "branch_final_disclosure_button_cancel";
  static readonly BRANCH_FINAL_DISCLOSURE_BUTTON_ACCEPT: string = "branch_final_disclosure_button_accept";
  static readonly BRANCH_FINAL_DISCLOSURE_BUTTON_EDIT: string = "branch_final_disclosure_button_edit";
  static readonly BRANCH_FINAL_DISCLOSURE_BUTTON_RESET: string = "branch_final_disclosure_button_reset";
  static readonly BRANCH_FINAL_DISCLOSURE_BUTTON_CLOSE: string = "branch_final_disclosure_button_close";

  // Account Applicant Disclosures
  static readonly BRANCH_ACCOUNT_APPLICANT_DISCLOSURE_BUTTON_SIGNATURE: string = "branch_account_applicant_disclosure_button_signature";
  static readonly BRANCH_ACCOUNT_APPLICANT_DISCLOSURE_BUTTON_PRINT: string = "branch_account_applicant_disclosure_button_print";
  static readonly BRANCH_ACCOUNT_APPLICANT_DISCLOSURE_BUTTON_EMAIL: string = "branch_account_applicant_disclosure_button_email";
  static readonly BRANCH_ACCOUNT_APPLICANT_DISCLOSURE_BUTTON_CANCEL: string = "branch_account_applicant_disclosure_button_cancel";
  static readonly BRANCH_ACCOUNT_APPLICANT_DISCLOSURE_BUTTON_ACCEPT: string = "branch_account_applicant_disclosure_button_accept";
  static readonly BRANCH_ACCOUNT_APPLICANT_DISCLOSURE_BUTTON_EDIT: string = "branch_account_applicant_disclosure_button_edit";
  static readonly BRANCH_ACCOUNT_APPLICANT_DISCLOSURE_BUTTON_RESET: string = "branch_account_applicant_disclosure_button_reset";
  static readonly BRANCH_ACCOUNT_APPLICANT_DISCLOSURE_BUTTON_CLOSE: string = "branch_account_applicant_disclosure_button_close";

  //Change Password state
  static readonly BRANCH_CHANGE_PASSWORD_REQUIRED: string = "This is a required field.";
  static readonly BRANCH_CHANGE_PASSWORD_NOT_MATCH: string = "The passwords provided do not match.";

  //Roles state
  static readonly BRANCH_ROLES_SELECTED_APPLICANTS_TITLE: string = "branch_roles_selected_applicants_title";
  static readonly BRANCH_ROLES_BUTTON_TAX_OWNER: string = "branch_roles_button_tax_owner";
  static readonly BRANCH_ROLES_SELECTED_ROLES_TITLE: string = "branch_roles_selected_roles_title";
  static readonly BRANCH_ROLES_BUTTON_SUGGEST: string = "branch_roles_button_suggest";
  static readonly BRANCH_ROLES_JOINT: string = "branch_roles_joint";
  static readonly BRANCH_AUTHORIZEDBYALL_BUTTON: string = "branch_authorizedByAll_button";

  //Mailing state
  static readonly BRANCH_MAILING_INPUT_TITLE_ONE: string = "branch_mailing_input_title_one";
  static readonly BRANCH_MAILING_INPUT_TITLE_TWO: string = "branch_mailing_input_title_two";
  static readonly BRANCH_MAILING_INPUT_TITLE_THREE: string = "branch_mailing_input_title_three";
  static readonly BRANCH_MAILING_SELECT_ADDRESS_HEADER: string = "branch_mailing_select_address_header";
  static readonly BRANCH_MAILING_BUTTON_SUGGEST: string = "branch_mailing_button_suggest";

  //Documents state
  static readonly BRANCH_DOCUMENT_HEADER: string = "branch_document_header";

  //Loan Summary State
  static readonly BRANCH_LOAN_CREDIT_CONFIRMATION: string = "branch_loan_credit_confirmation";
}
